import React from 'react';
import Header from '../components/Header';


class Index_dev extends React.Component {
    render(){
        return (
            <React.Fragment>
            <div style={{ height: '100%' , marginBottom: '500px'}}>
            <Header />
            <h1 style={{ textAlign: 'center' , textShadow: '3px 3px black' }}>SITO WEB IN COSTRUZIONE</h1>
            </div>
            </React.Fragment>
        )
    }
}

export default Index_dev;